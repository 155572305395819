"use client";

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Image from "next/image";
import { iconpath } from "@/util/staticresourcespath";
import { ImageTrip } from "../member-travel";

type Props = {
    item: ImageTrip;
};

const DialogImage = (props: Props) => {
    return (
        <Dialog.Root key={props.item.id}>
            <Dialog.Trigger asChild>
                <div key={props.item.id} className="relative w-full h-full items-center image-center">
                    <Image
                        src={props.item.imageUrl}
                        alt={props.item.imageAlt}
                        width={0}
                        height={0}
                        className="w-[180px] h-[110px] sm:w-[150px] sm:h-[135px] cursor-pointer object-cover"
                        unoptimized
                    />
                </div>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed bg-black bg-opacity-20 inset-0" />
                <Dialog.Content className="fixed outline-none left-[50%] top-[50%] z-9999 w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 max-sm:px-4">
                    <div className="flex flex-col sm:flex-row h-full mt-8">
                        <div className="relative">
                            <Image
                                src={props.item.imageUrl}
                                alt={props.item.imageAlt}
                                width={0}
                                height={0}
                                className="h-full w-full object-contain"
                                unoptimized
                            />
                        </div>
                    </div>

                    <Dialog.Close asChild>
                        <div className="inline-flex absolute top-0 right-4 sm:right-0 w-17 h-17 z-10">
                            <Image
                                src={iconpath + "icon_close.png"}
                                alt={"icon_close"}
                                width={24}
                                height={24}
                                style={{ width: "100%", height: "100%" }}
                                className="cursor-pointer"
                                unoptimized
                            />
                        </div>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default DialogImage;
